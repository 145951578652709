import EN from './locales/en';
import ES from './locales/es';
import FR from './locales/fr';
import DE from './locales/de';

export const LOCALES = {
  en: EN,
  es: ES,
  fr: FR,
  de: DE,
};
