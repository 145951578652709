import widgets from 'widjet';
import {queue} from '../utils/animations';

widgets.define('animation-queue', (options) => (el) => {
  const animations = JSON.parse(el.dataset.animations);
  let repeat = parseInt(el.dataset.animationRepeat, 10);

  if(isNaN(repeat)) { repeat = 0; }
  if(repeat < 0) { repeat = Number.POSITIVE_INFINITY; }

  queue(el, animations, repeat);
});
