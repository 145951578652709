import {asArray} from 'widjet-utils';
import {DisposableEvent} from 'widjet-disposables';

const SLEEP_RE = /sleep-(\d+)/;

export function cleanupAnimations(el) {
  asArray(el.classList).forEach((c) => {
    if (/animate__/.test(c)) {
      el.classList.remove(c);
    }
  });
}

export function animate(el, animation) {
  let match, delay, speed;

  if(animation instanceof Object) {
    delay = animation.delay;
    speed = animation.speed;
    animation = animation.animation;
  }

  return new Promise((resolve, reject) => {
    cleanupAnimations(el);
    if((match = animation.match(SLEEP_RE)) != null) {
      setTimeout(resolve, parseInt(match[1], 10));
    } else {
      el.classList.add(`animate__${animation}`);
      el.classList.add('animate__animated');

      if(delay) { el.classList.add(`animate__delay-${delay}s`); }
      if(speed) { el.classList.add(`animate__${speed}`); }

      const animationEnd = new DisposableEvent(el, 'animationend', () => {
        animationEnd.dispose();
        resolve();
      })
    }
  });
}

export function queue(el, animations, repeat=0) {
  return animations.reduce((p, a) => {
    return p.then(() => {
      return animate(el, a);
    });
  }, Promise.resolve()).then(() => {
    repeat--;
    if(repeat >= 0) {
      return queue(el, animations, repeat);
    }
  });
}
